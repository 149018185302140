import React from 'react'
import TopSection from './TopSection';
import Box from '../../components/Box';
import MainMenu from './MainMenu';

const Header = () => {
  return (
    <Box
      css={{
        position: 'fixed',
        width: '100%',
        top: 0,
      }}
    >
      <TopSection />
      <MainMenu />
    </Box>
  );
}

export default Header;