import React from 'react'
import Box from './Box'

const SocialMedia = () => {
    return (
        <Box
            className='social-media-container'
            css={{
                display: 'flex',
                gap: 15,
                a: {
                    display: 'flex',
                    flexDirection: 'column',
                },
                '.social-media-icon': {
                    transition: '0.2s',
                    height: 22,
                    width: 22,
                    backgroundSize: 'cover',
                }
            }}
        >
            <a href="#"><Box className='social-media-icon' css={{ backgroundImage: 'url(/social/facebook.svg)', '&:hover': { backgroundImage: 'url(/social/facebook-colored.svg)' } }} /></a>
            <a href="#"><Box className='social-media-icon' css={{ backgroundImage: 'url(/social/instagram.svg)', '&:hover': { backgroundImage: 'url(/social/instagram-colored.svg)' } }} /></a>
            <a href="#"><Box className='social-media-icon' css={{ backgroundImage: 'url(/social/whatsapp.svg)', '&:hover': { backgroundImage: 'url(/social/whatsapp-colored.svg)' } }} /></a>
            <a href="#"><Box className='social-media-icon' css={{ backgroundImage: 'url(/social/kakao-talk.svg)', '&:hover': { backgroundImage: 'url(/social/kakao-talk-colored.svg)' } }} /></a>
            <a href="#"><Box className='social-media-icon' css={{ backgroundImage: 'url(/social/line.svg)', '&:hover': { backgroundImage: 'url(/social/line-colored.svg)' } }} /></a>
        </Box>
    )
}

export default SocialMedia