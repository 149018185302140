import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import Box from '../components/Box';
import ComponentContext from './ComponentContext';

const MobileMenu = () => {
    const { isShowingMenu, onShowMenu } = useContext(ComponentContext);

    if (!isShowingMenu) return null;

    return (
        <Box
            css={{
                position: 'fixed',
                background: '#FFF',
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
            }}
        >
            <Box
                css={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    button: {
                        background: 'transparent',
                        border: 0,
                        height: 'fit-content',
                        marginTop: 20,
                        marginRight: 30,
                        img: {
                            height: 36
                        }
                    }
                }}
            >
                <button type="button" onClick={() => { onShowMenu(false) }}><img src="/close.png" alt="close icon" /></button>
            </Box>
            <Box
                css={{
                    flexGrow: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 30,
                    textAlign: 'center',
                    a: {
                        color: '#000',
                        textDecoration: 'none',
                        fontWeight: 600,
                    },
                }}
            >
                <Link onClick={() => { onShowMenu(false) }} to="/iron">IRON</Link>
                <Link onClick={() => { onShowMenu(false) }} to="/company">COMPANY</Link>
                <Link onClick={() => { onShowMenu(false) }} to="/industry">BUSINESS</Link>
                <Link onClick={() => { onShowMenu(false) }} to="/news">NEWS</Link>
                <Link onClick={() => { onShowMenu(false) }} to="/contact">CONTACT</Link>
            </Box>
        </Box>
    )
}

export default MobileMenu