import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { Container, InnerContainer } from '../../components/Container'
import Box from '../../components/Box';
import ComponentContext from '../ComponentContext';
import { getLogoUrl } from '../../utils';

const MainMenu = () => {
    const { onShowMenu } = useContext(ComponentContext);

    return (
        <Container
            css={{
                background: '#FFF',
                padding: '0px 2em',
            }}
        >
            <InnerContainer
                css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                    gap: 25,
                    '@md': {
                        gridTemplateColumns: 'auto 1fr',
                    }
                }}
            >
                <Box
                    css={{
                        img: {
                            height: 60,
                        }
                    }}
                >
                    <Link to="/"><img src={getLogoUrl()} alt="logo" /></Link>
                </Box>
                <Box
                    css={{
                        display: 'none',
                        flexDirection: 'column',
                        alignItems: 'end',
                        '@md': {
                            display: 'flex',
                        }
                    }}
                >
                    <Box
                        css={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 50,
                            a: {
                                color: '#000',
                                textDecoration: 'none',
                                fontWeight: 600,
                                transition: '0.3s',
                                '&:hover': {
                                    color: '#AAACAE'
                                }
                            },
                        }}
                    >
                        <Link to="/iron">IRON</Link>
                        <Link to="/company">COMPANY</Link>
                        <Link to="/industry">BUSINESS</Link>
                        <Link to="/news">NEWS</Link>
                        <Link to="/contact">CONTACT</Link>
                    </Box>
                </Box>
                <Box
                    css={{
                        '@md': {
                            display: 'none',
                        },
                        button: {
                            background: 'transparent',
                            padding: 0,
                            display: 'flex',
                            border: 0,
                            marginBottom: 5,
                            '@md': {
                                display: 'none',
                            }
                        },
                        img: {
                            height: 36,
                        }
                    }}
                >
                    <button type="button" onClick={() => { onShowMenu(true); }}><img src="/hamburger_menu.png" alt="hamburger menu icon" /></button>
                </Box>
            </InnerContainer>
        </Container>
    )
}

export default MainMenu