import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import menuData from './menuData';
import Wrapper from './Wrapper';
import ComponentContextProvider from './ComponentContextProvider';
import MobileMenu from './MobileMenu';
import { Container, InnerContainer } from '../components/Container';
import Loading from '../components/Loading';

const NoMatch = () => {
    return (
        <div>404 Not Found</div>
    )
}

const PageLoader = () => {
    return (
        <Container>
            <InnerContainer
                css={{
                    padding: '0'
                }}
            >
                <Loading />
            </InnerContainer>
        </Container>
    )
}

const LayoutRoutes = () => {
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                {menuData.map(x => <Route key={x.path} path={x.path} component={x.component} exact={!!x.exact} />)}
                <Route path="*" component={NoMatch} />
            </Switch>
        </Suspense>
    )
}

const MainLayout = () => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <ComponentContextProvider>
            <Wrapper>
                <Header />
                <LayoutRoutes />
                <Footer />
            </Wrapper>
            <MobileMenu />
        </ComponentContextProvider>
    );
}

export default MainLayout;