import React from 'react'
import Box from './Box'

const Loading = () => {
    return (
        <Box
            css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: window.innerHeight - 240,
                img: {
                    width: 100,
                    height: 100,
                }
            }}
        >
            <img src="/loader.gif" alt="loader" />
            <h5>Please Wait ...</h5>
        </Box>
    )
}

export default Loading