import React, { useContext } from 'react'
import Box from '../../components/Box';
import { Container, InnerContainer } from '../../components/Container';
import FamilySite from './FamilySite';
import { getCountryByHostname } from '../../utils';
import SocialMedia from '../../components/SocialMedia';
import ComponentContext from '../ComponentContext';

const getEmailAddress = () => {
    let retval = 'info@ironman.us';
    const country = getCountryByHostname();

    switch (country) {
        case 'singapore':
            retval = 'info@ironman.sg';
            break;
        case 'indonesia':
            retval = 'info@ironman.co.id';
            break;
        case 'japan':
            retval = 'info@ironman.us';
            break;
        case 'korea':
            retval = 'info@ironman.co.kr';
            break;
        default:
            // do nothing
    }

    return retval;
}

const TopSection = () => {
    const { toast } = useContext(ComponentContext);
    const mailAddress = getEmailAddress();

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(mailAddress);
        toast.success("Copied to clipboard");
    }

    return (
        <Container
            css={{
                background: '#1E2020',
                color: '#FFF',
                fontWeight: 500,
                fontSize: '13px',
                padding: '6px 2em',
                img: {
                    height: 20,
                },
                a: {
                    color: 'inherit',
                    textDecoration: 'none'
                }
            }}
        >
            <InnerContainer
                css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    gap: 30,
                    height: 24,
                    alignItems: 'center',
                    img: {
                        height: 16
                    },
                    '.social-media-container': {
                        display: 'none'
                    },
                    '.social-media-icon': {
                        width: '16px !important',
                        height: '16px !important'
                    },
                    '@sm': {
                        gridTemplateColumns: '1fr auto auto',
                        '.social-media-container': {
                            display: 'flex',
                        }
                    }
                }}
            >
                <Box
                    css={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 7,
                        "&:hover": {
                            cursor: 'pointer'
                        }
                    }}
                    onClick={handleCopyToClipboard}
                >
                    <img src="/email_icon.png" alt="email icon" />
                    <span>{mailAddress}</span>
                </Box>
                <SocialMedia />
                <FamilySite />
            </InnerContainer>
        </Container>
    )
}

export default TopSection