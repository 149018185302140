import React from 'react'
import Box from '../../components/Box'

const FamilySite = () => {
    return (
        <Box
            css={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 8,
                position: 'relative',
                '.menu': {
                    display: 'none',
                },
                '&:hover': {
                    cursor: 'pointer',
                    '.menu': {
                        display: 'flex'
                    }
                }
            }}
        >
            <Box css={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>FAMILY SITE<img src="/down_icon.png" alt="down icon" /></Box>
            <Box
                className='menu'
                css={{
                    paddingTop: 26,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    textAlign: 'right',
                    width: 'max-content',
                    flexDirection: 'column',
                    a: {
                        background: '#1E2020',
                        padding: 12,
                        '&:hover': {
                            cursor: 'pointer',
                            background: '#303434',
                        }
                    }
                }}
            >
                <a href="https://ironman.us">IRON | Group</a>
                <a href="https://ironman.sg">IRON UNITED | Singapore</a>
                <a href="https://ironman.co.id">IRON ASSET INDONESIA | Indonesia</a>
                <a href="https://ironman.co.kr">IRON ASSET KOREA | Republic of Korea</a>
                <a href="https://jp.ironman.us">IRON ASSET JAPAN | Japan</a>
            </Box>
        </Box>
    )
}

export default FamilySite