export const getCountryByHostname = () => {
    const hostname = window.location.hostname;

    switch (hostname) {
        case 'www.ironman.sg':
            return 'singapore'
        case 'www.ironman.co.kr':
            return 'korea'
        case 'www.ironman.co.id':
            return 'indonesia'
        case 'jp.ironman.us':
            return 'japan'
        default:
        // do nothing
    }

    return null;
}


export const getLogoUrl = () => {
    let retval = 'group.png';
    const country = getCountryByHostname();

    switch (country) {
        case 'singapore':
            retval = 'singapore.png';
            break;
        case 'indonesia':
            retval = 'indonesia.png';
            break;
        case 'japan':
            retval = 'japan.png';
            break;
        case 'korea':
            retval = 'korea.png';
            break;
        default:
            // do nothing
    }

    return "/logo/" + retval;
}

export const getImageFromServer = (imageUrl) => {
    if (window.location.hostname === 'localhost') {
        return `http://localhost:1337${imageUrl}`;
    }

    return imageUrl;
}