import { lazy } from 'react';

const menuData = [
    {
        path: '/',
        component: lazy(() => import('../pages/Home')),
        exact: true,
    },
    {
        path: '/iron',
        component: lazy(() => import('../pages/Iron')),
    },
    {
        path: '/company',
        component: lazy(() => import('../pages/Company')),
    },
    {
        path: '/industry',
        component: lazy(() => import('../pages/Industry')),
    },
    {
        path: '/news/:type/:slug',
        component: lazy(() => import('../pages/News/NewsDetail')),
    },
    {
        path: '/news',
        component: lazy(() => import('../pages/News')),
    },
    {
        path: '/contact',
        component: lazy(() => import('../pages/Contact')),
    },
]

export default menuData;