import { styled } from '../stitches.config';

export const Container = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 2em',
});


export const InnerContainer = styled('div', {
    width: '100%',
    maxWidth: '700px',
    '@lg': {
        maxWidth: '900px',
    },
    '@xl': {
        maxWidth: '1000px',
    },
    '@2xl': {
        maxWidth: '1280px',
    },
});