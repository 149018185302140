import React from 'react'
import { Container, InnerContainer } from '../../components/Container';
import Box from '../../components/Box';
import FamilySite from './FamilySite';
import { getCountryByHostname, getLogoUrl } from '../../utils';
import SocialMedia from '../../components/SocialMedia';

const getCompanyName = () => {
  let retval = 'IRON UNITED PTE. LTD';
  const country = getCountryByHostname();

  switch (country) {
    case 'indonesia':
      retval = 'PT. IRON ASSET INDONESIA';
      break;
    case 'japan':
      retval = 'IRON ASSET JAPAN CO., LTD.';
      break;
    case 'korea':
      retval = 'IRON ASSET KOREA CO., LTD.';
      break;
    default:
    // do nothing
  }

  return retval;
}

const Footer = () => {
  return (
    <Container
      css={{
        background: '#1E2020',
        color: "#FFF",
        fontSize: 14,
        fontWeight: '500',
        padding: '1.5em'
      }}
    >
      <InnerContainer
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0,
        }}
      >
        <Box
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            alignItems: 'center',
            gap: 30,
            '.social-media-container': {
              display: 'none'
            },
            '@sm': {
              '.social-media-container': {
                display: 'flex'
              }
            }
          }}
        >
          <Box
            css={{
              img: {
                height: 60,
                filter: 'invert(1)',
              }
            }}
          >
            <img src={getLogoUrl()} alt="white company logo" />
          </Box>
          <Box>
            <SocialMedia />
          </Box>
          <Box>
            <FamilySite />
          </Box>
        </Box>
        <Box
          css={{
            marginTop: 6,
            borderBottom: "1px solid #FFFFFF26"
          }}
        />
        <Box
          css={{
            color: '#86888B',
            fontSize: 12,
            marginTop: 14,
            display: 'flex',
            flexDirection: 'column',
            '.social-media-container': {
              display: 'flex',
              marginTop: 8
            },
            '.footer-text': {
              marginTop: 15
            },
            '@sm': {
              '.social-media-container': {
                display: 'none'
              },
              '.footer-text': {
                marginTop: 0
              },
            }
          }}
        >
          <SocialMedia />
          <Box className='footer-text'>Copyright &copy; BY {getCompanyName()} All Right Reserved.</Box>
        </Box>
      </InnerContainer>
    </Container>
  )
}

export default Footer;