import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import ComponentContext from './ComponentContext';

export default class ComponentContextProvider extends Component {
    state = {
        isShowingMenu: false,
    }

    handleShowMenu = (isShowingMenu) => {
        this.setState({
            isShowingMenu,
        })
    }

    createContextValue = () => {
        return {
            ...this.state,
            toast,
            onShowMenu: this.handleShowMenu
        }
    }

    render() {
        const { children } = this.props;

        const contextValue = this.createContextValue();

        return (
            <ComponentContext.Provider value={contextValue}>
                {children}
                <Toaster />
            </ComponentContext.Provider>
        )
    }
}
